/**
 * global styles
 */
html {
  background-color: #171c24;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.75);
}

input:focus {
  outline: none!important;
}

/*
 * table formatting
 */
tr {
  margin-bottom: 5px;
}
td {
  padding-top: 10px;
  padding-bottom: 10px;
}
tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
tr:hover td {
  background-color: transparent;
}
tr:hover th {
  background-color: #171c24;
}
th {
  padding-top: 15px;
  padding-bottom: 15px;
}

/*
 * mui select overrides
 */
.MuiFormControl-root {
  border: 1px solid rgba(144, 202, 249, 0.5)!important;
  margin-right: 15px!important;
}
.MuiInput-underline::before {
  display: none!important;
}
.MuiInput-input {
  padding-left: 5px!important;
  color:#90caf9!important;
  font-weight:bold!important;
  font-size:0.875rem!important;
  text-transform: uppercase!important;
}

/*
 * mui button overrides
 */
.MuiButton-root {
  border-radius: 0px!important;
}
.MuiSvgIcon-root {
  color: rgb(144, 202, 249)!important;
}

/*
 * react-datetime-picker overrides
 */
.react-datetime-picker {
  margin-right: 15px;
  border: 1px solid rgba(144, 202, 249, 0.5);
}
.react-datetime-picker__wrapper {
  border: none!important;
}
.react-datetime-picker__inputGroup > input,
.react-datetime-picker__inputGroup__amPm,
.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__amPm,
.react-datetime-picker__inputGroup__leadingZero {
  color: rgb(144, 202, 249);
  font-weight: bold!important;
  font-size: 0.875rem!important;
}
.react-datetime-picker__clear-button,
.react-datetime-picker__button {
  display: none;
}
.react-datetime-picker__inputGroup,
.react-datetime-picker__inputGroup > input {
  min-height: 30px;
}
.react-datetime-picker__inputGroup__amPm > option {
  background-color: #121212;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px!important;
}

.no-results {
  padding-left: 15px;
}
.unauthorized {
  padding: 15px;
}